import React from 'react';
import styles from './UpdateData.module.css'; // Import the CSS module


function BlackButton({ onClick, children, className = '', ...rest }) {
  return (
    <button
      className={`${styles.blackButton} ${className}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
}

export default BlackButton;
