import React, { useState, useEffect, useRef } from 'react';
import styles from './Chat.module.css';
import Note from './Note';  // Import the Note component

function Chat({ brain, instruct, vectorstore, persona, currentName, currentAvatar, addNote, removeNote }) {
    const [message, setMessage] = useState('');
    const [responses, setResponses] = useState([]);
    const [activeStars, setActiveStars] = useState([]);  // State to track active stars
    const [initialized, setInitialized] = useState(false);  // State to track initialization
    const [responsePending, setResponsePending] = useState(false);  // State to track if a response is pending
    const ws = useRef(null);
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);  // Ref for the input field
    const apiUrl = process.env.REACT_APP_API_URL;
    const model = "gpt-4o";
    const threadId = useRef(null);  // Store the thread ID
    const instructions = instruct;

    const connectWebSocket = () => {
        ws.current = new WebSocket(`${apiUrl}`);

        ws.current.onopen = async () => {
            console.log('WebSocket connection opened');

            // Only generate a new threadId if it doesn't exist yet
            if (!threadId.current) {
                threadId.current = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
            }

            // Initialize the thread and preload files (use existing threadId if available)
            await initializeThreadAndPreloadFiles();
            setInitialized(true);
        };

        ws.current.onmessage = (event) => {
            try {
                let data = event.data;
                console.log('Received data:', data);

                let parsedData;
                try {
                    parsedData = JSON.parse(data);
                } catch (e) {
                    // If it's not JSON, proceed with text processing
                    parsedData = null;
                }

                // Check if it's a system message (e.g., "init", "error")
                if (parsedData && parsedData.type) {
                    if (parsedData.type === 'init') {
                        console.log(`Thread initialized with ID: ${parsedData.threadId}`);
                        return;  // Skip adding to chat UI
                    } else if (parsedData.type === 'error') {
                        console.error('Server error:', parsedData.message);
                        return;  // Skip adding to chat UI
                    }
                }

                // Proceed with regular message processing (e.g., chat responses)
                if (!parsedData) {
                    data = data.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
                    const parts = data.split('\n').reduce((acc, part) => {
                        if (acc.length > 0) acc.push('<br />');
                        acc.push(part);
                        return acc;
                    }, []);

                    setResponses(prevResponses => prevResponses.map((resp, idx, arr) => {
                        if (idx === arr.length - 1) {
                            return { ...resp, content: [...resp.content, ...parts] };
                        }
                        return resp;
                    }));

                    // Reset response pending state
                    setResponsePending(false);

                    // Focus back on the input box once the response is processed
                    inputRef.current?.focus();
                }
            } catch (error) {
                console.error('Error handling message:', error);
            }
        };

        ws.current.onclose = () => {
            console.log("WebSocket connection closed, retrying in 5 seconds...");
            setTimeout(() => connectWebSocket(), 5000); // Retry after 5 seconds
        };
    };

    useEffect(() => {
        connectWebSocket();

        return () => {
            ws.current.close();
        };
    }, []);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [responses]);

    const initializeThreadAndPreloadFiles = async () => {
        let assistant = brain;
        let vstore = vectorstore;
        let instructions = "Initialize thread and preload files";

        ws.current.send(JSON.stringify({
            assistant: assistant,
            vectorstore: vstore,
            instructions: instructions,
            content: "initialize",
            threadId: threadId.current  // Reuse existing threadId
        }));
    };

    const sendMessage = () => {
        if (message.trim() && initialized && !responsePending) {
            let assistant = brain;
            let vstore = vectorstore;

            ws.current.send(JSON.stringify({
                assistant: assistant,
                vectorstore: vstore,
                instructions: instructions,
                content: message + " " + persona,
                threadId: threadId.current  // Reuse existing threadId
            }));
            setResponses(prevResponses => [
                ...prevResponses,
                { id: prevResponses.length, input: message, content: [], avatar: currentAvatar }
            ]);
            setMessage('');
            resetTextareaHeight();
            setResponsePending(true);  // Set response pending state
        }
    };

    const resetTextareaHeight = () => {
        const textarea = document.querySelector(`.${styles.textarea}`);
        if (textarea) {
            textarea.style.height = 'auto';
        }
    };

    const handleStarClick = (response) => {
        if (activeStars.includes(response.id)) {
            setActiveStars(prevActiveStars => prevActiveStars.filter(id => id !== response.id));
            removeNote(response.id);
        } else {
            addNote({
                id: response.id,
                question: response.input,
                response: response.content.join(''),
                avatar: response.avatar  // Ensure the avatar is passed here
            });
            setActiveStars(prevActiveStars => [...prevActiveStars, response.id]);
        }
    };

    const handleRemoveClick = (responseId) => {
        setResponses(prevResponses => prevResponses.filter(response => response.id !== responseId));
        removeNote(responseId);
        setActiveStars(prevActiveStars => prevActiveStars.filter(id => id !== responseId));
    };

    const handleInputChange = (e) => {
        setMessage(e.target.value);
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    return (
        <div className={styles.chat}>
            <div className={styles.gradient}></div>
            <div className={styles.messages}>
                {responses.map((response) => (
                    <div key={response.id} className={styles.response}>
                        <div className={styles.input}>
                            <div className={styles.star_container}>
                                <div
                                    className={`${styles.star_left} ${activeStars.includes(response.id) ? styles.active : ''}`}
                                    onClick={() => handleStarClick(response)}
                                >
                                    &#9733;
                                </div>
                                <div className={styles.star_center}>{response.input}</div>
                                <div className={styles.star_right} onClick={() => handleRemoveClick(response.id)}>&times;</div>
                            </div>
                        </div>
                        <div className={styles.responseContainer}>
                            <div className={styles.avatarContainer}>
                                <img src={response.avatar} alt="Avatar" className={styles.avatar} />
                            </div>
                            <div className={styles.responseContent}>
                                <div dangerouslySetInnerHTML={{ __html: response.content.join('') }} />
                            </div>
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div className={styles['input-area']}>
                <textarea
                    ref={inputRef}  // Attach the ref here
                    value={message}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            sendMessage();
                        }
                    }}
                    placeholder="Enter your message"
                    className={styles.textarea}
                    disabled={responsePending}  // Disable textarea when response is pending
                />
                <button className={styles.ask} onClick={sendMessage} disabled={!initialized || responsePending}>Ask</button>
            </div>
        </div>
    );
}

export default Chat;
