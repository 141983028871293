// App.js
import React, { useState, useEffect } from 'react';
import Chat from './Chat';
import Trait from './Trait';
import Note from './Note';
import NoteModal from './Modalnotes'; // Renamed for clarity
import UploadModal from './Modal'; // New Upload Modal component
import LanguageSelector from './LanguageSelector';
import styles from './App.module.css';
import ShowPersona from './ShowPersona';
import DropdownMenu from './DropdownMenu';
import UpdateData from './UpdateData';
import logo from './logo.png'; // Import the image
import elogo from './elogo.png'; // Import the image

function App() {
  // Existing State Variables
  const [persona, setPersona] = useState('');
  const [personas, setPersonas] = useState('');
  const [selectedMemberIndex, setSelectedMemberIndex] = useState();
  const [currentPersona, setCurrentPersona] = useState(null);
  const [currentName, setCurrentName] = useState(null);
  const [currentAvatar, setCurrentAvatar] = useState(null);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null); // State for selected note
  const [speaklang, setSpeakLang] = useState('English'); // State for selected language
  const [brain, setBrain] = useState(null);
  const [vectorstore, setVectorStore] = useState(null);

  // New State Variables for Upload Modal
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  // Handler for opening the Upload Modal
  const openUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  // Handler for closing the Upload Modal
  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  // Handler for successful upload
  const handleUploadSuccess = () => {
    setIsUploadModalOpen(false);
    window.location.reload(); // Reload the browser upon successful upload
  };

  // Existing Handler (Commented out as it's unused)
  const handleTextChange = (text) => {
    // setPersona(text);
  };

  const epochTimeInSeconds = Math.floor(new Date().getTime() / 1000);

  const [config, setConfig] = useState(null);
  useEffect(() => {
    fetch(`/config.json?${epochTimeInSeconds}`)
      .then(response => response.json())
      .then(data => {
        setConfig(data.main);
        setPersonas(data['main'].experts);

        setBrain(data['main'].assistant['brain']);
        setVectorStore(data['main'].assistant['vectorstore']);
        //console.log(data['main'].experts); // Process your data here
      })
      .catch(error => {
        console.error('Error fetching config.json:', error);
      });
  }, [epochTimeInSeconds]);

  const handleSelectedMemberChange = (index) => {
    setSelectedMemberIndex(index);
    setCurrentName(personas.staff[index].name);
    setCurrentAvatar(personas.staff[index].avatar);
    let name = personas.staff[index].name;
    let blurb = personas.staff[index].blurb;
    let pbackground = personas.staff[index].background;
    let persona = personas.staff[index].persona;
    let communication_style = personas.staff[index].communication_style;
    let quirks = personas.staff[index].quirks;
    let influence = personas.staff[index].influence;
    let teaching_style = personas.staff[index].teaching_style;
    let jargon_examples = personas.staff[index].jargon_examples;
    let jargon_amount = personas.staff[index].jargon;
    let guidance = config.guidance;
    let ask = config.eco.ask;
    let profile = `${ask}: ${name} is true to who they are defined in their persona document, this is at the core of who they are. Use their Communication Style and Quirks when responding.`;

    if (speaklang !== "English") {
      profile = `Return the response in ${speaklang}, as if a native speaker of ${speaklang} ` + profile;
    }
    console.log("hi", speaklang);
    setCurrentPersona(profile);
    setPersona(profile);
  };

  const addNote = (note) => {
    setNotes(prevNotes => [...prevNotes, note]);
  };

  const removeNote = (id) => {
    setNotes(prevNotes => prevNotes.filter(note => note.id !== id));
  };

  const handleNoteClick = (note) => {
    setSelectedNote(note);
  };

  const handleCloseNoteModal = () => {
    setSelectedNote(null);
  };

  const handleLanguageChange = (lang) => {
    setSpeakLang(lang);
    // Optionally, update persona based on new language
  };

  useEffect(() => {
    if (selectedMemberIndex != null) {
      handleSelectedMemberChange(selectedMemberIndex);
    }
  }, [speaklang]);

  return (
    <div className={styles.app}>
      {/* Header Section */}
      <div className={styles.header}>
        {config && <div className={styles.logotype} dangerouslySetInnerHTML={{ __html: config.experience.egotype }}></div>}
        {config && <div className={styles.titletype} dangerouslySetInnerHTML={{ __html: config.experience.title }}></div>}

        <div className={styles.logoright}>



          <img src={logo} className={styles.logotop} alt="logo" />
        </div>
      </div>

      {/* Main Content */}
      <div className={styles.main}>
        {/* Left Column */}
        <div className={`${styles.column} ${styles.left}`}>
          <div className={styles.hide}>
            <Trait onTextChange={handleTextChange} />
          </div>

          {config && (
            <div className={styles.EgosHolder}>
              <ShowPersona
                config={config}
                personas={personas}
                onSelectedMemberChange={handleSelectedMemberChange}
                currentAvatar={currentAvatar}
              />
            </div>
          )}

          {config && config.experience.explainer && (
            <div className={styles.Explainer}>
              {config.experience.explainer}
            </div>
          )}


        </div>

        {/* Center Column */}
        <div className={`${styles.column} ${styles.center}`}>
          {config && (
            <Chat
              brain={brain}
              instruct={config.eco.instruction}
              vectorstore={vectorstore}
              persona={persona}
              currentName={currentName}
              currentAvatar={currentAvatar}
              addNote={addNote}
              removeNote={removeNote} // Pass the removeNote function
            />
          )}
        </div>

        {/* Right Column */}
        <div className={`${styles.column} ${styles.right}`}>
          <div className={styles.coltitle}>Notes</div>
          <div className={styles.notes}>
            {notes.map((note, index) => (
              <Note
                key={index}
                question={note.question}
                response={note.response}
                avatar={note.avatar}
                onClick={() => handleNoteClick(note)} // Set the selected note on click
              />
            ))}
          </div>
        </div>
      </div>

      {/* Note Modal */}
      {selectedNote && (
        <NoteModal
          question={selectedNote.question}
          response={selectedNote.response}
          onClose={handleCloseNoteModal}
        />
      )}

      {/* Upload Modal */}
      {isUploadModalOpen && (
        <UploadModal
          onClose={closeUploadModal}
          onUploadSuccess={handleUploadSuccess}
          documentType="persona" // Specify the document type
          vectorstore={vectorstore} // Pass the vectorstore ID
          braindocs={config.brains}
        />
      )}

      {/* Footer Section */}
      <div className={styles.footer}>
        <div className={styles.langholder}>
          <LanguageSelector onLanguageChange={handleLanguageChange} />
        </div>

        <UpdateData onClick={openUploadModal} style={{ marginTop: '20px' }}>
          Update Data
        </UpdateData>
        
        © Barbarian 2024
      </div>
    </div>
  );
}

export default App;
