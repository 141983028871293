import React, { useState, useEffect } from 'react';
import styles from './Modal.module.css';

function Modal({ onClose, onUploadSuccess, vectorstore, braindocs }) {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // Track modal visibility
  const docs = braindocs?.docs || [];

  const apiUploadUrl = process.env.REACT_APP_UPLOAD_URL;
  // Track which files are selected via checkboxes
  const [selectedFiles, setSelectedFiles] = useState(
    docs.reduce((acc, doc) => {
      acc[doc.name] = true; // Default to having all checkboxes checked
      return acc;
    }, {})
  );

  // Effect to show the modal with fade-in
  useEffect(() => {
    setIsVisible(true);
  }, []);

  // Function to handle closing the modal
  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, 250); // Match fade-out duration
  };

  // Toggle file selection
  const handleCheckboxChange = (name) => {
    setSelectedFiles((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  // Check if any files are selected
  const anyFilesSelected = Object.values(selectedFiles).some((selected) => selected);

  // Function to handle upload
  const handleUpload = async () => {
    setIsUploading(true);
    setError(null);

    try {
      const filesToUpload = docs
        .filter((doc) => selectedFiles[doc.name])
        .map((doc) => ({
          name: doc.name,
          link: doc.link,
        }));

      if (filesToUpload.length === 0) {
        setError("Please select at least one file to upload.");
        setIsUploading(false);
        return;
      }

      //const response = await fetch('http://localhost:4000/upload-test', {
        const response = await fetch(`${apiUploadUrl}/upload-test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          vectorstore,
          docs: filesToUpload,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to upload document.');
      }

      // Call the success handler if upload succeeds
      onUploadSuccess();
    } catch (err) {
      console.error('Error uploading documents:', err);
      setError(err.message);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className={`${styles.modalOverlay} ${isVisible ? styles.modalShow : styles.modalHide}`}>
      {!isUploading ? (
        <div className={styles.modalContent}>
          <div className={styles.innerContent}>
            <div className={styles.subhead1}>Refresh Documents</div>

            <div className={styles.fileList}>
              {docs.length > 0 ? (
                docs.map((doc, index) => (
                  <div key={index} className={styles.fileItem}>
                    <label className={styles.fileLabel}>
                      <input
                        type="checkbox"
                        checked={selectedFiles[doc.name]}
                        onChange={() => handleCheckboxChange(doc.name)}
                        className={styles.checkbox}
                      />
                      <a href={doc.link} target="_blank" rel="noopener noreferrer" className={styles.fileName}>
                        {doc.name}
                      </a>
                    </label>
                  </div>
                ))
              ) : (
                <p>No documents available.</p>
              )}
            </div>

            {error && <p className={styles.error}>{error}</p>}

            <div className={styles.buttonGroup}>
              <button
                className={styles.blackButton}
                onClick={handleUpload}
                disabled={isUploading || !anyFilesSelected} // Disable if uploading or no files selected
              >
                {isUploading ? 'Uploading...' : 'Upload'}
              </button>
              <button
                className={styles.blackButton}
                onClick={handleClose}
                disabled={isUploading}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.blackModalContent}>
        <div className={styles.innerContent}>
          <span className={styles.headline}>Refreshing Files</span>
          <p className={styles.subhead}>
            Vectorizing Files For Better Responses<br/>This Can Take A Few Minutes
          </p>
          <p className={styles.body}>
            The App Will Refresh When the Process is Complete
          </p>
        </div>
      </div>


      )}
    </div>
  );
}

export default Modal;
